<template>
  <v-app>
    <v-navigation-drawer
      id="nav-drawer"
      v-model="navOpen"
      app
      right
      hide-overlay
      floating
    >
      <v-text-field
        v-model="currentSearchArgument"
        placeholder="Search..."
        clearable
        rounded
        hide-details="auto"
        @keypress.enter="addToSearchArray()"
      >
        <template v-slot:append-outer>
          <v-btn text rounded small>
            <v-icon @click="addToSearchArray()"> mdi-magnify </v-icon>
          </v-btn>
        </template>
      </v-text-field>
      <v-chip
        v-for="(name, index) in searchArray"
        :key="index"
        class="ma-1"
        small
        close
        @click:close="removeFromSearchArray(name)"
      >
        {{ name }}
      </v-chip>

      <v-list dense>
        <v-list-item-group mandatory :value="lessons[selectedLesson].id">
          <v-list-item-title>Values</v-list-item-title>
          <v-list-item
            v-for="item in values"
            :key="item.id"
            @click="navItemClick(item.id)"
          >
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item-title>Morals</v-list-item-title>
          <v-list-item
            v-for="moral in morals"
            :key="moral.id"
            @click="navItemClick(moral.id)"
          >
            <v-list-item-title>
              {{ moral.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append id="copyright">
        <div>&copy; Chris Lenaghan, 2022</div>
      </template>
    </v-navigation-drawer>

    <v-main id="main">
      <v-app-bar color="transparent" flat ref="top">
        <v-avatar>
          <img src="../public/img/icons/apple-touch-icon-152x152.png" />
        </v-avatar>
        <v-btn v-if="showOtherInstall" @click="installPwa"
          >Install?&emsp;
          <v-icon @click="dismissInvite">mdi-close</v-icon>
        </v-btn>
        <v-btn v-if="showOtherUpdate" @click="updatePwa"
          >Update?&emsp;
          <v-icon @click="dismissInvite">mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!showTimer" text @click="navOpen = !navOpen">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container id="ios-install-message-container">
        <div id="ios-install-message" v-if="showIosInstall">
          <span>
            Install this as an app!
            <br />
            Tap <v-icon>mdi-export-variant</v-icon> then "Add to Home Screen"
            <v-icon>mdi-plus-box-outline</v-icon>
            <v-icon id="close-ios-invite" @click="dismissIosInvite()" small
              >mdi-close</v-icon
            >
          </span>
        </div>
      </v-container>
      <v-container>
        <v-row id="first-screen" style="height: 100vh">
          <v-col cols="12" style="padding-top: 33vh; height: 50vh">
            <span id="focussed-lesson-title">
              {{ lessons[selectedLesson].title }}
            </span>
          </v-col>
          <v-col id="countdown-container" cols="12" style="height: 50vh">
            <v-btn
              id="cancel-countdown"
              text
              x-small
              v-if="showCancelTimer"
              @click="cancelTimer"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
            <div v-if="showTimer" id="countdown"></div>
          </v-col>
          <v-speed-dial
            v-if="!showTimer"
            text
            v-model="fabExpand"
            direction="top"
            transition="scale-transition"
          >
            <template v-slot:activator>
              <v-btn v-model="fabExpand" text fab>
                <v-icon small> mdi-circle-slice-8 </v-icon>
              </v-btn>
            </template>
            <v-btn
              fab
              text
              small
              @click="
                $vuetify.goTo($refs.fullText, {
                  duration: 750,
                  easing: 'easeInOutCubic',
                  offset: 0,
                })
              "
            >
              <v-icon>mdi-text-long</v-icon>
            </v-btn>
            <v-btn fab text small @click="startTimer(120)">
              <v-badge overlap bottom color="transparent" content="2">
                <v-icon>mdi-head-dots-horizontal</v-icon>
              </v-badge>
            </v-btn>
            <v-btn fab text small @click="startTimer(300)">
              <v-badge overlap bottom color="transparent" content="5">
                <v-icon>mdi-head-dots-horizontal</v-icon>
              </v-badge>
            </v-btn>
            <v-btn fab text small @click="randomLesson">
              <v-icon>mdi-shuffle-variant</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-row>

        <v-row style="height: 10vh" id="second-screen" ref="fullText">
          <v-col cols="12">
            <v-btn
              text
              @click="
                $vuetify.goTo($refs.top, {
                  duration: 750,
                  easing: 'easeInOutCubic',
                  offset: 0,
                })
              "
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row style="min-height: 90vh">
          <v-col cols="12">
            <div>
              <p>
                {{ lessons[selectedLesson].title }}
              </p>
              <p v-html="lessons[selectedLesson].body" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import lessonsData from "./assets/lessons.json";

export default {
  name: "App",

  data: () => ({
    navOpen: false,
    showIosInstall: false,
    showOtherInstall: false,
    showOtherUpdate: false,
    refreshing: false,
    registration: null,
    installEvent: null,
    fabExpand: false,
    lessons: lessonsData,
    selectedLesson: 3,
    currentSearchArgument: "",
    searchArray: [],
    showTimer: false,
    showCancelTimer: false,
    intervalId: -1,
    values: [],
    morals: [],
  }),

  watch: {
    searchArray: function () {
      this.filterLessons();
    },

    navOpen: function (navState) {
      let main = document.getElementById("main");
      let navDrawer = document.getElementById("nav-drawer");
      navState
        ? (main.style.transform = "translate(-256px)")
        : (main.style.transform = "translate(0px)");
      navState
        ? navDrawer.addEventListener(
            "touchmove",
            function (e) {
              this.preventTouchMove(e);
            }.bind(this),
            {
              capture: true,
              passive: false,
            }
          )
        : navDrawer.removeEventListener(
            "touchmove",
            function (e) {
              this.preventTouchMove(e);
            }.bind(this),
            {
              capture: true,
              passive: false,
            }
          );
    },
  },

  beforeMount() {
    // Detects if device is on iOS
    let userAgent = window.navigator.userAgent.toLowerCase();
    let isIos = /iphone|ipad|ipod/.test(userAgent);
    // Detects if device is in standalone mode
    let isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;
    // sets display ios install notification to true if ios and standalone mode
    if (isIos && !isInStandaloneMode()) {
      this.showIosInstall = true;
    }
    // otherwise, shows other install invite
    else {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.installEvent = e;
        this.showOtherInstall = true;
      });

      document.addEventListener("swUpdated", this.showRefreshUI, {
        once: true,
      });
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        });
      }
    }
  },

  mounted() {
    this.randomLesson();
    this.filterLessons();
  },

  methods: {
    filterLessons() {
      let searchRegex = new RegExp(this.searchArray.join("|"), "gi");
      if (searchRegex) {
        this.values = this.lessons.filter(
          (i) => i.type == "value" && searchRegex.test(i.title)
        );
        this.morals = this.lessons.filter(
          (i) => i.type == "moral" && searchRegex.test(i.title)
        );
      } else {
        this.values = this.lessons.filter((i) => i.type == "value");
        this.morals = this.lessons.filter((i) => i.type == "moral");
      }
    },

    preventTouchMove(event) {
      event.preventDefault();
    },

    dismissIosInvite() {
      this.showIosInstall = false;
    },

    dismissInvite() {
      this.showOtherInstall = false;
    },

    installPwa() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        this.dismissInvite(); // Hide the prompt once the user has clicked
        if (choice.outcome === "accepted") {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      });
    },

    showRefreshUI (e) {
      this.registration = e.detail;
      this.showOtherUpdate = true;
    },

    updatePwa() {
      this.showOtherUpdate = false;

      if(!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
      console.log("message sent to sw to 'skip waiting'")
    },

    randomLesson() {
      this.selectedLesson = Math.floor(Math.random() * this.lessons.length);
    },

    navItemClick(id) {
      this.navOpen = false;
      this.selectedLesson = id;
    },

    addToSearchArray() {
      if (
        this.currentSearchArgument &&
        !this.searchArray.includes(this.currentSearchArgument)
      ) {
        this.searchArray.push(this.currentSearchArgument);
      }
      this.currentSearchArgument = "";
    },

    removeFromSearchArray(name) {
      let i = this.searchArray.indexOf(name);
      this.searchArray.splice(i, 1);
    },

    cancelTimer() {
      clearInterval(this.intervalId);
      this.intervalId = -1;
      this.showCancelTimer = false;
      this.showTimer = false;
      document.getElementById("countdown").innerHTML = null;
    },

    startTimer(time) {
      this.showCancelTimer = true;
      this.showTimer = true;
      let timeLeft = time;
      let newTimer = setInterval(
        function () {
          if (timeLeft <= 0) {
            this.showTimer = false;
            this.$vuetify.goTo(this.$refs.fullText, {
              duration: 750,
              easing: "easeInOutCubic",
              offset: 0,
            });
            this.cancelTimer();
          } else {
            let m = Math.floor(timeLeft / 60);
            let s = (timeLeft % 60).toString().padStart(2, 0);
            document.getElementById(
              "countdown"
            ).innerHTML = `<h1>${m}:${s}</h1>`;
            timeLeft -= 1;
          }
        }.bind(this),
        1000
      );
      this.intervalId = newTimer;
    },
  },
};
</script>

<style>
.theme--light {
  color: white !important;
}

.theme--light input {
  color: white !important;
}

.theme--light ::placeholder {
  color: lightgrey !important;
}

.v-btn {
  background: transparent !important;
  border-radius: 30px !important;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1),
    -4px -4px 8px rgba(255, 255, 255, 0.3) !important;
}

.v-btn:before {
  background-color: transparent !important;
}

.v-btn--active,
.v-btn--focus {
  box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.v-ripple__container {
  display: none;
}

#app {
  margin: 0;
  touch-action: none;
  background-color: rgba(134, 143, 143, 1) !important;
}

.v-navigation-drawer {
  background-color: transparent !important;
  padding: 0vh 0vh 3vh 3vh;
  box-shadow: 0px 0px rgba(0, 0, 0, 0) !important;
  border: 0px !important;
}

.v-navigation-drawer__append {
  text-align: center;
}

.v-navigation-drawer--open ~ .v-main .v-toolbar__content .v-btn {
  box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.v-navigation-drawer .v-input {
  display: flex;
  align-items: center;
  margin: 0px;
  height: 56px;
  padding: 4px;
}

.v-navigation-drawer .v-input__control {
  box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: white !important;
}

.v-navigation-drawer .v-icon.v-icon.v-icon--link {
  color: lightgray !important;
}

.v-navigation-drawer .v-chip {
  background: transparent !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1),
    -2px -2px 4px rgba(255, 255, 255, 0.3) !important;
}

.v-navigation-drawer .theme--light.v-chip:not(.v-chip--active) {
  background: transparent !important;
}

.v-navigation-drawer .v-menu__content {
  box-shadow: none;
}

.v-navigation-drawer .v-list {
  margin: 0vh;
  padding: 4px;
}

.v-navigation-drawer .v-list-item {
  margin: 0px 0px 6px 0px;
  min-height: 0px !important;
  height: 3vh;
  border-radius: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1),
    -2px -2px 4px rgba(255, 255, 255, 0.3) !important;
}

.v-navigation-drawer .v-list-item:before {
  background-color: transparent;
}

.v-navigation-drawer .v-list-item--active {
  box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.v-navigation-drawer #copyright {
  text-align: center;
}

#ios-install-message-container {
  position: fixed;
  bottom: 4px;
  text-align: center;
  z-index: 5;
}

#ios-install-message {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 20px 1px 10px;
  background-color: rgba(82, 88, 88, 1);
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  color: white;
  text-align: center;
}

#ios-install-message:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(82, 88, 88, 1);
  border-bottom: 10px solid transparent;
  bottom: -20px;
  left: 47%;
}

#close-ios-invite {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
}

#first-screen {
  position: relative;
  text-align: center;
}

/*
#focussed-lesson-title {
  position: relative;
  padding: 0.65em 1em;
  margin: 0;
  border-radius: 0.25em;
  box-decoration-break: clone;
  animation-name: highlight;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes highlight {
  from {
    background-image: linear-gradient(
        90deg,
        rgba(134, 143, 143, 0) 0%,
        rgba(134, 143, 143, 0) 0.1%,
        rgba(134, 143, 143, 1) 0.9%,
        rgba(234, 143, 143, 1) 100%
      ),
      linear-gradient(
        104deg,
        rgba(130, 255, 173, 0) 0.9%,
        rgba(130, 255, 173, 1.25) 2.4%,
        rgba(130, 255, 173, 0.5) 5.8%,
        rgba(130, 255, 173, 0.1) 93%,
        rgba(130, 255, 173, 0.7) 96%,
        rgba(130, 255, 1732, 0) 98%
      );
    background-size: 1000%, auto 10px;
    background-repeat: repeat-x;
    background-position: right,bottom;
  }

  to {
    background-image: linear-gradient(
        90deg,
        rgba(134, 143, 143, 0) 0%,
        rgba(134, 143, 143, 0) 99.1%,
        rgba(134, 143, 143, 1) 99.9%,
        rgba(234, 143, 143, 1) 100%
      ),
      linear-gradient(
        104deg,
        rgba(130, 255, 173, 0) 0.9%,
        rgba(130, 255, 173, 1.25) 2.4%,
        rgba(130, 255, 173, 0.5) 5.8%,
        rgba(130, 255, 173, 0.1) 93%,
        rgba(130, 255, 173, 0.7) 96%,
        rgba(130, 255, 1732, 0) 98%
      );
    background-size: 1000%, auto 10px;
    background-repeat: repeat-x;
    background-position: left,bottom;
  }
}
*/

#countdown-container {
  position: relative;
  text-align: center;
}

#countdown {
  width: 30%;
  position: absolute;
  left: 35%;
  box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 16px;
}

#cancel-countdown {
  position: absolute;
  left: 65%;
}

.v-speed-dial {
  position: absolute;
  bottom: 25%;
  left: 80%;
}

#second-screen {
  text-align: center;
}
</style>
